import React from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import Link from 'next/link'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material/'
import { StyledTextA } from '@components/atoms/StyledTextA'
import { ArticlesCategoryContainerFragment } from '@services/graphcms/client'

const PREFIX = 'CategoryCard'

const classes = {
  media: `${PREFIX}-media`,
  cardText: `${PREFIX}-cardText`,
  category: `${PREFIX}-category`,
  link: `${PREFIX}-link`,
}

const StyledCard = styled(Card)(() => ({
  [`& .${classes.media}`]: {
    height: 0,
    maxHeight: '400px',
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.cardText}`]: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },

  [`& .${classes.category}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.link}`]: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

type Props = {
  articles: ArticlesCategoryContainerFragment[]
  category: string
  url: string
  imageUrl: string
}

export const CategoryCard = (props: Props) => (
  <StyledCard elevation={0}>
    <StyledTextA>
      <CardActionArea>
        <Link href={props.url}>
          <Typography className={classes.category} variant="subtitle1">
            {props.category}
          </Typography>
        </Link>
        <Link href={props.url}>
          <CardMedia className={classes.media} image={props.imageUrl} title={props.category} />
        </Link>
      </CardActionArea>
      <CardContent className={classes.cardText}>
        {props.articles.map((article) => (
          <Link href={`/article/${article.id}`} key={article.id}>
            <a>
              <Typography className={classes.link} gutterBottom={true}>
                {`${article.title} ${moment(article.publishDate).format('MM/DD h:mm')}`}
              </Typography>
            </a>
          </Link>
        ))}
      </CardContent>
    </StyledTextA>
  </StyledCard>
)
