import React from 'react'
import axios from 'axios'
import { sortBy } from 'lodash'
import dynamic from 'next/dynamic'
import AppDrawer from '@components/Sidebar/AppDrawer'
import Footer from '@components/organisms/Footer'
import Navigation from '@components/organisms/Navigation'
import { initializeApollo } from '@graphql/apolloClient'
import {
  GetInformationTopDocument,
  GetArticlesDocument,
  GetAdDistributionManagementDocument,
  GetArticlesCategoryContainerDocument,
  GetPickupCatalogDocument,
  GetPickupCatalogQuery,
  AdDeliveryDestination,
} from '@services/graphcms/client'
import { Top } from '@containers/Home/Top'
import { NewsContext } from '@contexts/index'
import {
  Article as ArticleRanking,
  Catalog as CatalogRanking,
} from '@services/coataz/models/ranking'
import {
  GetCatalogTopDocument,
  GetCatalogTopQuery,
  GetCatalogTopQueryVariables,
  Platform,
  OrderBy,
} from '@services/cloud/client'

const DynamicLeadingSignupFloatingButton = dynamic(
  import('../components/organisms/LeadingSignupFloatingButton'),
  { ssr: false },
)

type Props = React.ComponentProps<typeof Top> & {
  articlesRanking: ArticleRanking[]
  catalogsRanking: CatalogRanking[]
}

type RankingFetch = {
  articles: Props['articlesRanking']
  catalogs: Props['catalogsRanking']
}

export default function HomePage(props: Props) {
  return (
    <>
      <AppDrawer>
        <NewsContext.Provider
          value={{
            articles: props.articlesRanking,
            catalogs: props.catalogsRanking,
          }}
        >
          <Top {...props} />
          <React.Suspense fallback={<div />}>
            <DynamicLeadingSignupFloatingButton />
          </React.Suspense>
        </NewsContext.Provider>
        <Footer />
        <Navigation />
      </AppDrawer>
    </>
  )
}

export async function getStaticProps() {
  const { data: dataRanking }: { data: RankingFetch } = await axios.get(
    `${String(process.env.NEXT_PUBLIC_REACT_APP_FUNCTIONS)}/get_top_queries`,
  )

  const apolloClient = initializeApollo()

  const nextSeoValues = {
    title: 'COATAZ（コーターズ） | 塗料業界に特化した総合情報サイト',
    description:
      'COATAZは塗料業界に特化したニュース、製品カタログや企業情報を掲載している塗料業界の総合情報サイトです。',
    canonical: 'https://coataz.com',
    openGraph: {
      url: 'https://coataz.com',
      title: 'COATAZ（コーターズ） | 塗料業界に特化した総合情報サイト',
      description:
        'COATAZは塗料業界に特化したニュース、製品カタログや企業情報を掲載している塗料業界の総合情報サイトです。',
    },
  }

  const {
    data: { pickupCatalogs },
  } = await apolloClient.query<GetPickupCatalogQuery>({
    query: GetPickupCatalogDocument,
  })

  const pickupCatalogIds = pickupCatalogs.map((catalog) => catalog.catalogId)

  const { data } = await apolloClient.query<GetCatalogTopQuery, GetCatalogTopQueryVariables>({
    query: GetCatalogTopDocument,
    variables: {
      args: {
        limit: 12,
        skip: 0,
        orderBy: OrderBy.Desc,
        where: {
          catalogIds: pickupCatalogIds ?? [],
          platform: Platform.Coataz,
        },
      },
    },
  })

  const sortedPickupCatalogs = sortBy(
    data?.pickupCatalogs?.map((catalog) => {
      const filteredPickupCatalog = pickupCatalogs
        .filter((pickupCatalog) => pickupCatalog.catalogId === catalog?.catalogId)
        .reduce((o) => o)

      return { ...filteredPickupCatalog, ...catalog }
    }),
    (o) => o.sort,
  )

  await apolloClient.query({
    query: GetArticlesDocument,
    variables: {
      skip: 1,
      first: 14,
    },
  })

  await apolloClient.query({
    query: GetArticlesCategoryContainerDocument,
  })

  await apolloClient.query({
    query: GetInformationTopDocument,
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top1,
    },
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top2,
    },
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top3,
    },
  })

  return {
    props: {
      articlesRanking: dataRanking.articles,
      catalogsRanking: dataRanking.catalogs,
      catalogs: data.catalogs,
      firstDepthCategories: data.firstDepthCategories,
      rankings: [],
      initialApolloState: apolloClient.cache.extract(),
      pickupCatalogs: sortedPickupCatalogs,
      nextSeo: nextSeoValues,
    },
    revalidate: 60,
  }
}
