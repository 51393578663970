import React from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Container, Paper, Tab, Tabs, Typography } from '@mui/material'
import { RankingType } from '@services/coataz/models/ranking'
import { Ranking } from './Ranking'

const PREFIX = 'RankingTab'

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.appBar}`]: {
    borderBottom: '2px solid #e8e8e8',
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
}))

function RankingTab() {
  const [value, setValue] = React.useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <StyledPaper className={classes.root} elevation={0} variant="outlined">
      <Container className={classes.root}>
        <Typography align="center" className={classes.title} gutterBottom={true} variant="h6">
          月間アクセスランキング
        </Typography>
      </Container>
      <AppBar className={classes.appBar} position="static" color="inherit" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="記事" />
          <Tab label="カタログ" />
        </Tabs>
      </AppBar>
      <Ranking type={value === 0 ? RankingType.ARTICLE : RankingType.CATALOG} />
    </StyledPaper>
  )
}

export default RankingTab
