import React from 'react'
import { Box } from '@mui/material'
import { FeedCard } from '@components/organisms/Article/FeedCard'
import { ArticlesFragment } from '@services/graphcms/client'

type Props = {
  story: ArticlesFragment
}

export const MainCarousel = (props: Props) => (
  <Box pt={1}>
    <FeedCard fullYear={true} article={props.story} />
  </Box>
)
