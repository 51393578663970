import React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Grid } from '@mui/material'
import { CategoryCard } from '@components/organisms/CategoryCard'
import {
  useGetArticlesCategoryContainerQuery,
  ArticlesCategoryContainerFragment,
} from '@services/graphcms/client'
import { ArticleCategory } from '@services/coataz/constants'

const PREFIX = 'CategoryContainer'

const classes = {
  category: `${PREFIX}-category`,
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.category}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

export function CategoryContainer() {
  const { data, loading } = useGetArticlesCategoryContainerQuery()

  if (loading) {
    return null
  }

  if (!data) {
    return null
  }

  return (
    <StyledContainer>
      <Grid className={classes.category} container={true} spacing={3}>
        <Grid item={true} xs={12} sm={4}>
          <CategoryCard
            articles={data.factory as ArticlesCategoryContainerFragment[]}
            category="工業"
            url={ArticleCategory.factory}
            imageUrl="/img/category/factory.webp"
          />
        </Grid>
        <Grid item={true} xs={12} sm={4}>
          <CategoryCard
            articles={data.architecture as ArticlesCategoryContainerFragment[]}
            category="建築"
            url={ArticleCategory.architecture}
            imageUrl="/img/category/architecture.webp"
          />
        </Grid>
        <Grid item={true} xs={12} sm={4}>
          <CategoryCard
            articles={data.topics as ArticlesCategoryContainerFragment[]}
            category="業界トピック"
            url={ArticleCategory.topics}
            imageUrl="/img/category/topics.webp"
          />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}
