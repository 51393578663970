import React from 'react'
import { styled } from '@mui/material/styles'
import Router from 'next/router'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import { ContainerLeft, ContainerRight } from '@components/atoms/Container'
import AdFeedCard from '@components/organisms/Ad/AdFeedCard'
import AdFeedList from '@components/organisms/Ad/AdFeedList'
import { FeedList } from '@components/organisms/Article/FeedList'
import { CatalogList } from '@components/organisms/CatalogList'
import { CatalogTopFragment, FirstDepthCatalogCategoriesFragment } from '@services/cloud/client'
import { useGetArticlesQuery, AdDeliveryDestination } from '@services/graphcms/client'
import { CategoryContainer } from '../CategoryContainer'
import { Informations } from '../Informations'
import { MainCarousel } from './MainCarousel'
import NewsTabs from '../NewsTabs'
import RankingTab from '../RankingTab'

const PREFIX = 'index'

const classes = {
  adCard: `${PREFIX}-adCard`,
  category: `${PREFIX}-category`,
  feedListContainer: `${PREFIX}-feedListContainer`,
  readMore: `${PREFIX}-readMore`,
  catalogMenuTitle: `${PREFIX}-catalogMenuTitle`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.adCard}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.category}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  [`& .${classes.feedListContainer}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.readMore}`]: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    color: 'black',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },

  [`& .${classes.catalogMenuTitle}`]: {
    fontWeight: 'bold',
  },
}))

type Props = {
  firstDepthCategories: FirstDepthCatalogCategoriesFragment[]
  catalogs: CatalogTopFragment[]
  pickupCatalogs: CatalogTopFragment[]
}

export function Top(props: Props) {
  const { data } = useGetArticlesQuery({
    variables: {
      skip: 1,
      first: 14,
    },
  })

  const feeds = data && data?.articles ? [...data.articles] : []

  return (
    <Root>
      <NewsTabs />
      <Container disableGutters={true}>
        <Grid container={true}>
          <Grid item xs={12} sm={8}>
            <ContainerLeft>
              {data && data?.articles && data.articles.length > 0 && (
                <>
                  {data &&
                    Array.isArray(data.carouselArticles) &&
                    data.carouselArticles.length > 0 && (
                      <MainCarousel story={data.carouselArticles[0]} />
                    )}
                  <AdFeedList destination={AdDeliveryDestination.Top1} />
                  <Grid className={classes.feedListContainer} container={true} spacing={1}>
                    {feeds.map((article, index) => {
                      if (!article) return null

                      return (
                        <Grid key={article.id} item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FeedList
                            key={index}
                            fullYear={false}
                            isThumbnail={true}
                            article={article}
                            variant="elevation"
                          />
                          <Divider />
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Button
                    className={classes.readMore}
                    fullWidth={true}
                    variant="outlined"
                    onClick={() => {
                      Router.push('/news/latest?page=1')
                    }}
                  >
                    もっと読む
                  </Button>
                  <AdFeedList destination={AdDeliveryDestination.Top2} />
                </>
              )}
            </ContainerLeft>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ContainerRight>
              <div className={classes.adCard}>
                <AdFeedCard destination={AdDeliveryDestination.Top3} />
              </div>
              <RankingTab />
              <Informations />
            </ContainerRight>
          </Grid>
        </Grid>
        <Divider className={classes.category} variant="middle" />
        <CategoryContainer />
        <Box pt={1} pb={1}>
          <Typography className={classes.catalogMenuTitle} variant="h6">
            カタログ新着
          </Typography>
          <Grid container={true} item={true} spacing={1}>
            {props?.catalogs &&
              Array.isArray(props.catalogs) &&
              props.catalogs.map((catalog) => (
                <Grid key={catalog?.catalogId} item={true} xs={6} sm={3} md={2}>
                  <CatalogList catalog={catalog} />
                </Grid>
              ))}
          </Grid>
        </Box>
        {props.pickupCatalogs &&
          Array.isArray(props.pickupCatalogs) &&
          props.pickupCatalogs.length > 0 && (
            <Box pt={1} pb={1}>
              <Typography className={classes.catalogMenuTitle} variant="h6">
                ピックアップカタログ
              </Typography>
              <Grid container={true} item={true} spacing={1}>
                {props.pickupCatalogs.map((catalog) => (
                  <Grid key={catalog?.catalogId} item={true} xs={6} sm={3} md={2}>
                    <CatalogList catalog={catalog} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
      </Container>
    </Root>
  )
}
