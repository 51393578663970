import { createContext } from 'react'
import { Article, Catalog } from '@services/coataz/models/ranking'

type NewsContextValue = {
  articles: Article[]
  catalogs: Catalog[]
}

export const NewsContext = createContext<NewsContextValue>({
  articles: [],
  catalogs: [],
})
