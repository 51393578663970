import { Timestamp } from 'firebase/firestore'
import { ArticleFragment } from '@services/graphcms/client'

export const RankingType = {
  ARTICLE: 'ARTICLE',
  CATALOG: 'CATALOG',
} as const
// eslint-disable-next-line
type Unpacked<T> = T extends { [K in keyof T]: infer U } ? U : never
export type RankingTypes = Unpacked<typeof RankingType>

export type Article = {
  id: string
  pageViews: number
  articleId: string
  title: string
  categories: ArticleFragment['categories'] | null
  tags: ArticleFragment['tags'] | null
  thumbnail: ArticleFragment['thumbnail'] | null
  publishDate: any
  createdAt: Timestamp | null
  updatedAt: Timestamp | null
}

export type Catalog = {
  id: string
  pageViews: number
  catalogId: string
  companyId: string
  title: string
  categories: ArticleFragment['categories'] | null
  tags: ArticleFragment['tags'] | null
  thumbnailUrls: any | null
  publishDate: any
  createdAt: Timestamp | null
  updatedAt: Timestamp | null
}
