import React from 'react'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'
import truncate from 'truncate'
import { Card, CardActionArea, CardContent, CardMedia, Divider, Typography } from '@mui/material/'
import { CatalogTopFragment } from '@services/cloud/client'

const PREFIX = 'CatalogList'

const classes = {
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  cardText: `${PREFIX}-cardText`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.media}`]: {
    height: 'auto',
    objectFit: 'scale-down',
    padding: theme.spacing(1),
    width: '100%',
  },

  [`& .${classes.cardText}`]: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}))

type Props = {
  catalog: CatalogTopFragment | null
}

export function CatalogList(props: Props) {
  const router = useRouter()

  if (!props.catalog) {
    return null
  }

  const thumbnailUrl =
    props.catalog?.thumbnailUrls?.[0]?.url ||
    'https://coataz.s3-ap-northeast-1.amazonaws.com/thumb.png'

  return (
    <StyledCard className={classes.card} elevation={0} variant="outlined">
      <CardActionArea
        onClick={() =>
          router.push(`/catalog/${props?.catalog?.companyId}/${props?.catalog?.catalogId}`)
        }
      >
        {Array.isArray(props.catalog?.thumbnailUrls) && props.catalog?.thumbnailUrls.length ? (
          <CardMedia className={classes.media} component="img" image={thumbnailUrl} />
        ) : (
          <>
            <CardMedia
              sx={{
                height: 'auto',
                width: '100%',
                objectFit: 'scale-down',
                p: 1,
              }}
              component="img"
              image={thumbnailUrl}
            />
            <Divider />
          </>
        )}
        <CardContent
          className={
            props.catalog?.thumbnailUrls && props.catalog?.thumbnailUrls[0]?.url
              ? classes.cardText
              : ''
          }
        >
          <Typography variant="body2">{props?.catalog?.title}</Typography>
          <Typography color="textSecondary" variant="body2">
            {truncate(props?.catalog?.company?.name || '', 13)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  )
}

export default CatalogList
