import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import Link from 'next/link'
import { Container, Divider, Paper, Typography } from '@mui/material'
import { useGetInformationTopQuery } from '@services/graphcms/client'

const PREFIX = 'Informations'

const classes = {
  marginTop: `${PREFIX}-marginTop`,
  header: `${PREFIX}-header`,
  a: `${PREFIX}-a`,
  title: `${PREFIX}-title`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.marginTop}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.header}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.a}`]: {
    color: 'black',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

export function Informations() {
  const { data, error, loading } = useGetInformationTopQuery()

  if (error) {
    return <div>404 ページが存在しません</div>
  }

  if (loading) {
    return null
  }

  return (
    <StyledPaper className={classes.marginTop} elevation={0} variant="outlined">
      <Container className={classes.marginTop}>
        <Typography align="center" className={classes.header} gutterBottom={true} variant="h6">
          お知らせ
        </Typography>
        <Divider />
        {data?.informations &&
          data?.informations.map((information) => (
            <Fragment key={information.id}>
              <Link href={'/information/[id]'} as={`/information/${information.id}`}>
                <a className={classes.a}>
                  <Typography className={classes.title}>
                    {`${information.title} ${moment(information.publishDate).format('MM/DD h:mm')}`}
                  </Typography>
                </a>
              </Link>
              <Divider />
            </Fragment>
          ))}
      </Container>
    </StyledPaper>
  )
}
