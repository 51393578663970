import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { Box, Card, CardActionArea, Container, Divider, Grid, Typography } from '@mui/material'
import { Image } from '@components/organisms/Article/Image'
import { NewsContext } from '@contexts/index'
import { RankingTypes } from '@services/coataz/models/ranking'

const PREFIX = 'Ranking'

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  media: `${PREFIX}-media`,
  catalogMedia: `${PREFIX}-catalogMedia`,
  number: `${PREFIX}-number`,
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.grid}`]: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  [`& .${classes.media}`]: {
    height: 0,
    maxHeight: '80px',
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.catalogMedia}`]: {
    width: '100%',
  },

  [`& .${classes.number}`]: {
    fontStyle: 'italic',
    marginRight: theme.spacing(1),
  },
}))

type Props = {
  type: RankingTypes
}

export function Ranking(props: Props) {
  const { articles, catalogs } = useContext(NewsContext)
  const router = useRouter()

  if (!articles || !Array.isArray(articles) || !catalogs || !Array.isArray(catalogs)) {
    return null
  }

  const filteredArticles = articles.filter((_, index) => index < 10)
  const filteredCatalogs = catalogs.filter((_, index) => index < 10)

  return (
    <StyledContainer className={classes.root}>
      {props.type === 'ARTICLE'
        ? filteredArticles.map((article, index) => (
            <Card key={index} elevation={0}>
              <CardActionArea onClick={() => router.push(`/article/${article.id}`)}>
                <Grid alignItems="center" className={classes.grid} container={true} item={true}>
                  <Grid item={true} xs={1}>
                    <Typography
                      className={classes.number}
                      color="secondary"
                      display="inline"
                      variant="h5"
                      style={{ fontStyle: 'italic' }}
                    >
                      {`${index + 1} `}
                    </Typography>
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={article.thumbnail ? 8 : 11}
                    spacing={article.thumbnail ? 1 : 0}
                  >
                    <Box pl={1} pr={1}>
                      <Typography>{article.title}</Typography>
                    </Box>
                  </Grid>
                  {article.thumbnail && (
                    <Grid item={true} xs={3}>
                      <Image article={article} styleImage={classes.media} />
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </CardActionArea>
            </Card>
          ))
        : filteredCatalogs.map((catalog, index) => (
            <Card key={index} elevation={0}>
              <CardActionArea
                onClick={() => router.push(`/catalog/${catalog.companyId}/${catalog.catalogId}`)}
              >
                <Grid alignItems="center" className={classes.grid} container={true} item={true}>
                  <Grid item={true} xs={1}>
                    <Typography
                      className={classes.number}
                      color="secondary"
                      display="inline"
                      variant="h5"
                      style={{ fontStyle: 'italic' }}
                    >
                      {`${index + 1} `}
                    </Typography>
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={Array.isArray(catalog.thumbnailUrls) ? 8 : 11}
                    spacing={Array.isArray(catalog.thumbnailUrls) ? 1 : 0}
                  >
                    <Box pl={1} pr={1}>
                      <Typography>{catalog.title}</Typography>
                    </Box>
                  </Grid>
                  {catalog.thumbnailUrls && Array.isArray(catalog.thumbnailUrls) && (
                    <Grid item={true} xs={3}>
                      <img className={classes.catalogMedia} src={catalog.thumbnailUrls[0].url} />
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </CardActionArea>
            </Card>
          ))}
    </StyledContainer>
  )
}

export default Ranking
